.view-selector-views-view-option {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: var(--fnbeigemeddark);
    transition: background 0.2s linear;
    font-weight: 400;
}

.view-selector-views-view-option-span {
    color: var(--fnblacklight);
    font-size: 14px;
}

.view-selector-views-view-option-span>svg {
    vertical-align: top;
    margin-right: 0.5rem;
}

.view-option-span-active {
    color: var(--fnblack);
    font-weight: 500;
}

.view-option-active {
    background: var(--viewselectoroptionactive);
}