/* MetricScore styles */

:root {
  --scorecard-value-sign-width: 20px;
}

.scorecard-content {
  height: 100%;
  width: 100%;
  padding: 20px;
  font-family: 'DM Sans', sans-serif !important;
  color: var(--fnfontlight) !important;
}

.scorecard-toprow {
  height: 56%;
  font-weight: 700;
  font-size: 150%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.scorecard-value-sign {
  float: left;
  width: var(--scorecard-value-sign-width);
  font-weight: normal;
}

.scorecard-value-number {
  float: left;
  margin-right: 12px;
  font-size: 1.5rem;
  font-weight: normal;
}

.scorecard-value-unit {
  float: left;
  font-size: 1.5rem;
  font-weight: normal;
}

.scorecard-bottomrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44%;
  font-size: 80%;
}

.scorecard-legend-color {
  float: left;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  margin-right: 8px;
  margin-left: var(--scorecard-value-sign-width);
}

.scorecard-legend-label {
  width: 100px;
  float: left;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  margin-left: var(--scorecard-value-sign-width);
  color: var(--lighterwhite);
  padding: 4px 0;
  border-radius: 10px;

}

/* .scorecard-bottomrow-left {
  height: 100%;
  width: 60%;
  float: left;
}

.scorecard-bottomrow-right {
  width: 40%;
  float: right;
} */

.scorecard-bottomrow-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.scorecard-button {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  font-weight: 700;
  font-size: 13px;
  border-radius: 20px;
  background: var(--fnbeigemeddark);
  color: var(--fnfontlight);
  margin-right: 1rem;
}

.scorecard-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  user-select: none;
}

.scorecard-button-separator {
  padding-right: 10px;
}

.scorecard-button-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 24px;
  border-radius: 42px;
  margin-right: 0.5rem;
  background-color: var(--fnfontlight);
  
}

/* @media screen and (max-width: 700px) {
  .container-fluid {
    --fontsizetext: 15px !important;
    --fontsizeamount: 20px !important;
    --fontsizeperiod: 18px !important;
  }
} */

@media (max-width: 1601px) {

  .scorecard-content {
    padding: 5px;
  }

  .scorecard-legend-color {
    width: 10px;
    height: 10px;
  }

  .scorecard-value-number {
    margin-right: 0.5rem;
  }

  .scorecard-value-number,
  .scorecard-value-unit { 
    font-size: 1.25rem;
  }

  .scorecard-button-text,
  .scorecard-legend-label {
    font-size: 0.625rem;
  }

  .scorecard-button-content .arrow-arrowhead-wrapper {
      margin: 0;
  }

  .scorecard-button-content .arrow-arrowhead-wrapper > svg {
    transform: scale(0.7) rotate(270deg);
  }
}