.cell {
    display: flex;
    color: var(--fnblack);
    font-weight: 500;
    padding: 0.75rem;
    border-bottom: 1px solid var(--graycloudy);
    border-right: 1px solid var(--graycloudy);
    min-width: 6rem;
    font-size: 0.875rem;
    box-sizing: content-box;
}

.cell-value {
    display: flex;
    justify-content: space-between;
    padding-top: 0.2rem;
}

.first-column {
    position: sticky;
    left: 0;
    border-left: 1px solid var(--graycloudy);
    background-color: var(--lighterwhite);
}