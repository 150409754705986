.refresh-button-container {
    height: 100%;
    float: right;
}

.refresh-button {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.refresh-button-text {
    font-family: 'DM Sans', sans-serif !important;
    color: var(--fnblack)
}