.tree-view {
    position: sticky;
    top: 0;
    width: fit-content;
    background-color: white;
    z-index: 100;
    
}

.tree-view-container {
    background: var(--lighterwhite);
    border-radius: 15px;
    font-family: 'DM Sans', sans-serif !important;
}

.tree-view-inner {
    /* border-left: 1px solid var(--graycloudy); */
    border-bottom: 1px solid var(--graycloudy);
    border-right: 1px solid var(--graycloudy);
    /* border-top-left-radius: 8px; */
    /* border-bottom-left-radius: 8px; */
    border-bottom-right-radius: 8px;
    width: fit-content;
    /* overflow: hidden; */
}

.tree-view-column {
    font-weight: 700;
    border-top: 1px solid var(--graycloudy);
    border-bottom: none;
    text-align: right;
}

.reforecast {
    background-color: #F5F1E8;
}

.tree-view-empty-cell {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--graycloudy);
    border-right: 1px solid var(--graycloudy);
    background-color: var(--lighterwhite);
    position: sticky;
    left: 0;
}

