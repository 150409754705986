/* tooltip styles */
/* TODO: this is very similar to scorecard style classes, try break down
in to reusable components */

.tooltip-wrapper {
    width: 180px;
    font-family: 'DM Sans', sans-serif !important;
    color: var(--fnblack);
    font-size: 12px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.tooltip-legend-wrapper {
    height: 100%;
    width: 100%;
}

.tooltip-label {
    text-align: center;
    font-weight: bold;
}