:root {
    --nav-width: 312px;
    --header-width: calc(100% - var(--nav-width));
    --header-height: 70px;
    --main-width: var(--header-width);
    --main-height: calc(100% - var(--header-height));
    --main-left-width: 75%;
    --main-right-width: calc(100% - var(--main-left-width));
    --padding: 12px;
}

body {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(254deg, #FBFBF6 0%, #F4F1E9 100%) !important;
}

main {
    position: fixed;
    left: var(--nav-width);
    top: 0px;
    width: var(--main-width);
    height: 100%;
    /* background-color: var(--fnbeigelight); */
    color: var(--purplenavy);
    padding: var(--padding);
    user-select: none;
}

header {
    width: 100%;
    height: var(--header-height);
    position: relative;
}

.title {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 36px;
    font-weight: 500;
    height: 100%;
    float: left;
    color: var(--fnfontlight);
    padding: 12px;
}

.content {
    width: 100%;
    height: var(--main-height);
}

section {
    float: left;
    height: 100%;
}

section.primary {
    width: var(--main-left-width);
    height: 100%;
}

section.secondary {
    width: var(--main-right-width);
    height: 100%;
}
