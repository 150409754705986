
.login-with-account {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    width: 26rem;
    border-radius: 25px;
    cursor: pointer;
    background-color: var(--fnloginback);
    padding: 0.5rem;
    /* transition: background-color 200ms linear; */
}

/* .login-with-account:hover {
    background-color: rgb(100, 218, 207, 0.1);
    transition: background-color 200ms linear;
} */
 

.company-logo img {
    margin-right: 1rem;
}
.login-text {
    color: var(--fnblack);
    font-weight: 600;
    font-size: 14px;
}


