.selector-month-container {
  height: 100%;
  width: 75%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: var(--fnblack);
  border: 1px solid var(--fnselectorborder);
  margin: 0 3px;
}

.selector-month-container>img {
  margin-right: 0.5rem;
}

.selector-month-wrapper {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selector-arrow-container-left {
  height: 100%;
  width: 12.5%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--fnselectorborder);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.selector-arrow-container-right {
  height: 100%;
  width: 12.5%;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--fnselectorborder);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.selector-dropdown-container {
  margin: 0px 10px 0px 10px;
  background: var(--lighterwhite);
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  font-family: 'DM Sans', sans-serif !important;
  z-index: 999;
}

.selector-dropdown-item {
  margin: 10px 0px 10px 0px;
  font-size: 13px;
  font-weight: 500;
  color: var(--darkergray);
  cursor: auto !important;
}

.selector-dropdown-item-active {
  margin: 10px 0px 10px 0px;
  font-size: 13px;
  font-weight: 500;
  color: var(--fnblack);
  cursor: pointer;
}

.selector-dropdown-item-not-active {
  margin: 10px 0px 10px 0px;
  font-size: 13px;
  font-weight: 500;
  color: var(--gray);
  cursor: none;
}

.selector-dropdown-item-active:hover {
  font-weight: 700;
}
