.view-selector-views-view {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: var(--fnbeigemeddark);
    transition: background 0.2s linear;
    font-weight: 400;
    color: var(--fnblack);
    cursor: auto;
}

.view-selector-views-view-span {
    color: var(--fnblacklight);
}

.views-view-span-active {
    color: var(--fnblack)
}

.view-selector-views-view-arrow {
    transform: rotate(-90deg);
}

.view-selector-views-view-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 18rem;
    top: 0;
    right: -18.25rem;
    background: var(--fnbeigemeddark);
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 999;
}

.view-active {
    background: var(--viewselectoroptionactive);
    cursor: pointer;
}
