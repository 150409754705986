.nav-menu-dropdown {
    top: unset;
    left: unset;
    width: unset;
    border-radius: unset;
    box-shadow: unset;
    padding: unset;
    margin-top: 2rem;
}

.nav-menu-lightturquoise {
    /* background: var(--fnbeigelight); */
    background: transparent;
}

.nav-menu-gray {
    /* background: var(--fnbeigelight); */
    background: transparent;
}

.nav-menu-dropdown-transition {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.nav-menu-dropdown-label {
    position: relative;
    font-size: 1.25rem;
    font-weight: 700;
    height: 3rem;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    position: sticky;
    top: 0;
}

.nav-menu-dropdown-label-mediumturquoise {
    /* background-color: var(--fnbeigelight); */
    color: var(--fnblack);
    
}

.nav-menu-dropdown-label-purplenavy {
    /* background-color: var(--fnbeigelight); */
    color: var(--fnblack);
}

.nav-menu-dropdown-label>span:first-of-type {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 5%;
    width: 4px;
    height: 90%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.nav-divisions-menu {
    margin-top: 3rem;
    margin-left: 2rem;
}

.nav-menu-dropdown-label-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 14px;
    border: 1px solid #D8D6CF;
    background: #F5F1E8;
    cursor: pointer;
}

.nav-menu-dropdown-label-arrow {
    display: inline-block;
    position: absolute;
    /* right: 1rem; */
    cursor: pointer;
}

.nav-menu-collapsed-show-options {
    position: absolute;
    top: 150px;
    left: 80px;
    width: 300px;
    background: var(--fnbeigemeddark);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 450px;
    overflow: scroll;
}

.nav-menu-collapsed-show-options-clients {
    position: absolute;
    top: 320px;
    left: 80px;
    width: 300px;
    background: var(--fnbeigemeddark);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 100;
    max-height: 450px;
    overflow: scroll;
}