
.login-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    background-color: var(--fnbeigelight);
    font-family: 'DM Sans', sans-serif !important;
    height: 100vh;
}

.login-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: var(--lighterwhite);
    border-radius: 16px;
    margin: 0 auto;
    width: 30rem;
    height: 15rem;
    padding: 2.5rem 0;
    box-shadow: 0px 5px 10px 0px rgba(58, 73, 125, 0.02);
}

.login-with-section {
    display: flex;
    flex-direction: column;
}

.login-welcome {
    font-family: 'Vesper Libre', serif;
    font-size: 28px;
    font-weight: 400;
    color: var(--fnblack);
    text-align: center;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0 2rem 0;
    height: 2rem;
}

.logo-container>img {
    height: 100%;
}

.login-title {
    font-family: 'DM Sans', sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.5px;
    margin-left: 0.5rem;
}

