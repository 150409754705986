.arrow-arrowhead-wrapper {
    height: auto;
    width: auto;
    margin: 0 10px;
}

.arrow-arrowhead-black-wrapper {
    height: auto;
    width: auto;
    margin: 0 10px 0 10px;
}

.arrow-arrowhead-black-margin-wrapper {
    height: auto;
    width: auto;
    margin: 0 10px 0 20px;
}

.arrow-arrowhead_turquoise-wrapper {
    height: auto;
    width: auto;
    margin: 0 10px;
}

.arrow-arrowhead-wrapper>svg>path {
    fill: var(--purplenavy);
}

.arrow-arrowhead_turquoise-wrapper>svg>path {
    fill: var(--mediumturquoise);
}

.arrow-circular-wrapper {
    height: auto;
    width: auto;
    margin: 0px 10px 0px 0px;
}

.arrow-circular-black-wrapper {
    height: auto;
    width: auto;
    margin: 0px 10px 0px 0px;
}

.arrow-circular-black-wrapper>img {
    vertical-align: top;
}

.arrow-circular-wrapper>svg>path {
    fill: var(--mediumturquoise);
}

.arrow-circular-svg {
    margin-right: 10px;
    transition: all 0.3s ease;
}

.loading .arrow-circular-svg {
    animation: rotate 1s linear infinite;
}

.left-arrow {
    transform: rotate(0deg);
}

.right-arrow {
    transform: rotate(180deg);
}

.up-arrow {
    transform: rotate(90deg);
}

.down-arrow {
    transform: rotate(270deg);
}

.small-arrow {
    width: 5px;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}