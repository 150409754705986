.nav-menu-dropdown-item {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 400;
    font-size: 0.875rem;
    color: var(--fnblacklight);
    cursor: pointer;
}

.nav-menu-dropdown-item:hover {
    font-weight: 600;
}

.nav-menu-dropdown-item-active {
    display: flex;
    justify-content: space-between;
    margin-left: 2rem;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--fnorange);
    cursor: pointer;
}

.nav-menu-dropdown-item-mediumturquoise {
    color: var(--fnorange);
}

.nav-menu-dropdown-item-purplenavy {
    color: var(--fnorange);
}

.nav-menu-dropdown-item-check {
    margin-right: 2rem;
    margin-left: 1rem;
}
