.button-back-container {
  height: 100%;
  float: right;
  transition: opacity 0.3s;
}

.button-back {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  color: var(--fnblack);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
