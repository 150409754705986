/* @import url('https://fonts.googleapis.com/css2?family=Corben:wght@400;700&family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Corben:wght@400;700&family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900&family=Vesper+Libre:wght@400;500;700;900&display=swap');



html,
body {
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: 'DM Sans', sans-serif !important; */
  font-family: 'Corben', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

:root {
  --white: #FFFCFC;
  --lighterwhite: #FFFFFF;
  --gray: #D8DBE5;
  --graycloudy: #EBEDF2;
  --darkergray: #B0B6CB;
  --mediumgray: #9DA4BE;
  --lightergray: #F3F6F9;
  --whitegray: #F5F6F8;
  --mediumturquoise: #64DACF;
  --lightturquoise: #F7FDFD;
  --bluelavender: #7580A4;
  --bluelavender-alt: #616D97;
  --purplenavy: #3A497D;
  --bluenavy: #0A2D5C;
  --platinumgray: #E5E5E5;
  --yellowsunshine: #F6CC48;
  --fnbeige: #ECDFC8;
  --fnbeigedarker: #E7BB9F;
  --fnbeigemeddark: #F5F1E8;
  --fnbeigelight: #FAF9F4;
  --fnbeigedropdown: #F0E9DB;
  --fnbeigedropdownactive: #E8DEC9;
  --fnblack: #1D1E14;
  --fnmedblack: #464646;
  --fnblacklight: rgba(29, 30, 20, 0.5);
  --fngrey: #E9E9E9;
  --fnorange: #FF784E;
  --fnyellow: #FDCF68;
  --fnyellowlight: #FFE9A6;
  --fnloginback: #F7F5ED;
  --fnrevenue: #D7C3A6;
  --fncogs: #42483D;
  --fngrossprofit: #F9DB7B;
  --fnopex: #757971;
  --fnnetincome: #95C4F3;
  --fnnonopex: #A6A8A3;
  --fnebitda: #FF908F;
  --fnselectorborder: #D8D6CF;
  --fnfontlight: #464646;
  --backbtn: #F5BFAB;
  --viewselectoroptionactive: #F1EADB;
}
