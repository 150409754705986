/* Nav */

nav {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 312px;
  height: 100%;
  color: var(--fnblack);
  /* background-color: var(--fnbeigelight); */
}

nav>header {
  width: 100%;
  height: 80px;
}

nav>footer {
  width: var(--nav-width);
  height: 80px;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

ul {
  width: calc(var(--nav-width) - 21px + var(--padding));
  height: auto;
  list-style: none;
  background: white;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

ul.nav-header-dropdown {
  position: absolute;
  z-index: 1000;
  top: 70px;
  left: 160px;
  padding: 1rem 2rem;
  font-family: 'DM Sans', sans-serif !important;
  background-color: var(--fnbeigemeddark);
}

ul.nav-header-dropdown-collapsed {
  position: absolute;
  z-index: 1000;
  top: 240px;
  left: 80px;
  padding: 1rem 2rem;
  font-family: 'DM Sans', sans-serif !important;
  background-color: var(--fnbeigemeddark);
}

.nav-footer-dropdown {
  position: fixed;
  top: calc(100% - 80px - 50px + 5px);
  width: calc(var(--nav-width) - 80px + var(--padding));
  height: 50px;
  left: 160px;
  z-index: 100;
  font-family: 'DM Sans', sans-serif !important;
  background-color: var(--fnbeigemeddark);
}

.nav-footer-dropdown-collapsed {
  position: fixed;
  top: calc(100% - 80px - 50px + 5px);
  width: calc(var(--nav-width) - 80px + var(--padding));
  height: 50px;
  left: 50px;
  z-index: 100;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif !important;
  background-color: var(--fnbeigemeddark);
}

nav>ul>li {
  font-size: 14px;
  padding: 12px 0px 12px 12px;
  user-select: none;
  font-weight: 500;
}

nav>ul>li:hover{
  font-weight:  700;
}



.nav-header-dropdown-item-text {
  font-size: 14px;
  color: var(--fnblacklight);
}

.nav-header-dropdown-item-text-active {
  font-size: 14px;
  color: var(--fnorange);
}

.nav-header-dropdown-item-icon {
  float: right;
}


/* Checkmark style */
.nav-header-dropdown-item-icon>svg {
  width: 14px;
  height: 14px;
}

.nav-header-dropdown-item-icon>svg>path{
  fill: var(--fnorange);
}

.nav-header-icon {
  height: 100%;
  position: absolute;
  top: 9px;
  left: 0;
  display: flex;
  padding: 17.5px;
  margin-left: 1rem;
}

.nav-header-icon-container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.nav-header-icon-container-no-logo {
  height: 100%;
  width: 100%;
  background: var(--gray);
  border-radius: 10px;
}

.nav-header-icon-image {
  height: 100%;
}

.nav-header-content {
  font-family: 'Montserrat';
  padding: 15px 0px;
  height: 100%;
  width: calc(100% - 80px - 15%);
  float: left;
}

.nav-header-button {
  position: absolute;
  top: 0;
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-header-button-container {
  height: 30px;
  width: 30px;
}

.nav-header-title {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  color: var(--fnblack);
}

.nav-header-subtitle {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 11px;
  font-weight: 500;
  color: var(--fnblacklight);
}

.nav-header-subtitle > span {
  margin-right: 5px;
}

.nav-divisions-label-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 14px;
  border: 1px solid #D8D6CF;
  background: #F5F1E8;
  cursor: pointer;
}

.nav-footer-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 14px;
    border: 1px solid #D8D6CF;
    background: #F5F1E8;
    cursor: pointer;
    margin-left: 2.2rem;
}

.nav-footer-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 100px;
}

.nav-footer-icon-container>img {
  width: 16px;
}

.nav-footer-content {
  font-family: 'Montserrat';
  padding: 15px 0px;
  height: 100%;
  width: calc(100% - 80px - 25%);
  float: left;
}

.nav-footer-button {
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-footer-button-container {
  height: 30px;
  width: 30px;
}

.nav-footer-text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 14px;
  font-weight: bold;
  color: var(--fnblack);
}

.nav-clients-menu-container {
  margin-top: 1.875rem;
  /* height: calc(100% - 280px); */
  /* overflow-y: scroll; */
}

.nav-clients-menu-container>ul {
  margin-top: 0;
  margin-bottom: 0;
}

.nav-header-dropdown-item-consolidation {
  position: relative;
  border-top: 1px solid #D8DBE5;
  margin-top: 0.75rem;
  padding-top: 1rem;
}

.nav-header-dropdown-item-consolidation-icon {
  margin-right: 0.75rem; 
  vertical-align: sub;
}

.nav-header-dropdown-consolidation-sub-menu {
  position: absolute;
    z-index: 1000;
    top: 0px;
    left: calc(var(--nav-width) - 50px + var(--padding));
    padding: 1rem 2rem;
    background-color: var(--fnbeigemeddark);
}

.nav-header-dropdown-consolidation-sub-menu-option {
  padding: 5px 0px 5px 0px;
}

.nav-header-dropdown-item-consolidation .nav-header-dropdown-item-text {
  font-weight: 500;
}

.nav-header-dropdown-item-consolidation .nav-header-dropdown-item-text:hover {
  font-weight: 700;
}
