.analyze-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2rem;
    border: none;
    border-radius: 20px;
    padding-right: 0.75rem;
    font-size: 0.6rem;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s linear;
    box-sizing: border-box;
    background: var(--fnbeigemeddark);
    color: var(--fnfontlight);
}

/* .analyze-btn>img {
    margin-left: 1rem;
    vertical-align: middle;
    transition: opacity 0.2s linear;
} */

.analyze-btn-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 24px;
    border-radius: 42px;
    background-color: var(--fnfontlight);
    box-sizing: border-box;
    margin-right: 0.5rem;
    transition: opacity 0.2s linear;
}