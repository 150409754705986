.view-toggle-container {
    position: relative;
    height: 100%;
    float: right;
    transition: opacity 0.3s;
    font-family: 'DM Sans', sans-serif !important;
    color: var(--fnblack)
}

.view-toggle-button {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    color: var(--fnblack);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-toggle-separator {
    height: 20px;
    border-left: 1px solid #D5D2CA;
    margin: 0 1rem;
}

.view-toggle-span {
    margin-left: 0.5rem;
}
